:root {
  --light: #e2dccf;
  --dark: #2a2a33;
  --darkest: #0c0c0f;
  --grayMedium: #908a7e;

  --red: #dc645f;
  --blue: #3880ff;

  /* Singular entity types */
  --artist: #eea565;
  --genre: #eea565;
  --question: #43bb84;
  --theme: #a42f6d;
  --song: #eea565;
  --sermon: #dc645f;
  --speaker: #dc645f;
  --verse: #6f67dd;

  /* Plural entity types */
  --artists: #eea565;
  --genres: #eea565;
  --questions: #43bb84;
  --themes: #a42f6d;
  --songs: #eea565;
  --sermons: #dc645f;
  --speakers: #dc645f;
  --verses: #6f67dd;
}

/* ion-content {
  --background: var(--light);
  --background: linear-gradient(180deg, var(--light) 0em, #ffffff 20em);
}

ion-content {
  --ion-background-color: none;
} */

ion-item {
  --padding-start: 0em;
}

ion-button:not(.button-clear) {
  --background: #a42f6d;
}

ion-menu-button,
ion-back-button,
ion-button.button-clear {
  --color: #a42f6d;
}

.content-shade {
  background: var(--light);
  background: linear-gradient(
    180deg,
    var(--light) 0em,
    rgba(255, 255, 255, 0) 20em
  );
  width: 100%;
  height: 30em;
  z-index: -1;
  position: absolute;
  /* top: 56px; */
}

ion-header.header-md::after {
  background: none !important;
}

ion-toolbar.toolbar-shade {
  --background: var(--light);
  /* background: none; */
  /* transition: all 0.2s ease; */
}

ion-toolbar.opaque {
  /* background: var(--light); */
}

ion-card {
  box-shadow: none;
  background: none;
  border: 1px solid var(--light);
}

ion-card.filled {
  background: white;
}

ion-card-title {
  color: var(--dark);
}

ion-card-subtitle {
  color: var(--grayMedium);
}

ion-chip {
  border-radius: 0.25em;
}

ion-title,
.toolbar-center {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 2em; /* To prevent the title text overlapping at least 1 icon */
  right: 2em;
  width: auto;
  text-align: center;
  height: 100%;
  padding: 0em 1em;
}

ion-icon:not(.clickable) {
  pointer-events: none;
}

ion-select::part(text) {
  font-weight: 500;
}

/* BIBLE VIEW */

.full-bible-chapter {
  margin: 1em;
  border-collapse: collapse;
}

.full-bible-chapter td {
  vertical-align: top;
  padding-bottom: 1em;
}

.full-bible-chapter .chapter-verse {
  color: var(--grayMedium);
  text-align: left;
  padding-right: 0.5em;
}

.full-bible-chapter .verse-text {
  color: var(--dark);
  font-weight: 500;
}

.verse-of-the-day {
  padding: 1em;
}

.verse-of-the-day .verse-name {
  color: var(--grayMedium);
  font-size: 0.9em;
  margin-bottom: 1em;
}

.verse-of-the-day .verse-text {
  color: var(--dark);
  font-size: 1.75em;
  line-height: 1.25em;
}
