.terms-of-service ol {
  margin: 0;
  padding: 0;
}
table td,
.terms-of-service table th {
  padding: 0;
}
.terms-of-service .c9 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: right;
}
.terms-of-service .c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .c8 {
  padding-top: 7.5pt;
  padding-bottom: 7.5pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.terms-of-service .c1 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.terms-of-service .c10 {
  background-color: #ffffff;
  max-width: 498.6pt;
  padding: 56.7pt 56.7pt 56.7pt 56.7pt;
}
.terms-of-service .c7 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.terms-of-service .c3 {
  font-size: 12pt;
  font-weight: 700;
}
.terms-of-service .c4 {
  font-size: 18pt;
  font-family: "Arial";
  font-weight: 700;
}
.terms-of-service .c0 {
  font-size: 12pt;
  font-weight: 400;
}
.terms-of-service .c6 {
  font-size: 10pt;
  font-weight: 400;
}
.terms-of-service .c5 {
  vertical-align: super;
}
.terms-of-service .c11 {
  height: 12pt;
}
.terms-of-service .title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service li {
  color: #000000;
  font-size: 12pt;
}
.terms-of-service p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
}
.terms-of-service h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
