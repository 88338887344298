.swiper {
  width: 100%;
  height: 100%;
}

.slider-slide-text-image {
  align-items: flex-start !important;
}

@keyframes bouncer {
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-30px);
  }
  25% {
    transform: translateX(-15px);
  }
  37.5% {
    transform: translateX(-30px);
  }
  50% {
    transform: translateX(0);
  }
}

.slider-slide-text-image .bounce {
  /* background-color: red; */
  animation-name: bouncer;
  animation-duration: 3s;
  animation-iteration-count: 2;
  animation-timing-function: ease;
}

.slider-slide-text-image .image-wrapper {
  width: 20em;
  /* background: rgba(0, 0, 0, 0.2); */
  bottom: 0em;
  overflow: hidden;
}

.slider-slide-text-image .image-wrapper .image {
  position: relative;
  width: 100%;
  height: 100em;
}

.slider-slide-text-image .image-wrapper img {
  width: 100%;
}

.slider-slide-text-image .image-text {
  display: flex;
  flex-direction: column;
  height: 15em;
  justify-content: center;
  padding: 0em 0.5em;
  padding-top: 3em;
}

.swiper-slide .swiper-slide-content {
  display: "flex";
  flex-direction: "column";
  width: 20em;
  max-width: 20em;
}
