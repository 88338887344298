.calendar .calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar .day-name {
  text-align: center;
}

.calendar-cell {
  position: relative;
  width: 5em;
  height: 5em;
}

.calendar-cell > :not(.number) {
  position: absolute;
  width: 100%;
  height: 100%;
}

.calendar-cell.out-month {
  opacity: 0.25;
}

.calendar-cell .number {
  position: absolute;
  font-size: 0.5em;
  margin: 1em;
}

.calendar-cell.current {
  background: rgba(0, 0, 0, 0.1);
}

.calendar .month-year {
  display: flex;
  text-align: center;
  align-items: center;
}

.calendar .next,
.calendar .previous {
  /* font-size: 1.5em; */
}
